<template>
  <v-container> </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    // 
  }),
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  created() {
    this.$store.dispatch("logout");
    this.$store.dispatch("resetCoin");
    this.$store.dispatch("resetPick");
    this.$store.dispatch("resetSkin");
    this.$store.dispatch("resetApparel");
    this.$store.dispatch("resetTutorial");
    this.$store.dispatch("resetBoss");
    this.$store.dispatch("resetEvent");
    this.$store.dispatch("resetSeries");
    this.$store.dispatch("resetGameCache");
  },
  mounted() {
      if (this.$route.query && this.$route.query.code) {
        this.$router.push({
          name: "PageAccessMain",
          query: { code: this.$route.query.code.substring(0, 9) },
        });
      } else {
        this.$router.push({ name: "PageAccessMain" });
      }
  },
};
</script>
